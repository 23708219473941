<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1" style="height: auto">
        การเงิน (สาขา) <v-icon>mdi-chevron-right</v-icon>
        <span class="font-weight-regular body-1"> ใบแจ้งหนี้ ค่าธรรมเนียม </span
        ><v-icon>mdi-chevron-right</v-icon>
        <span class="font-weight-regular body-1"> รายละเอียดใบแจ้งหนี้ </span>
      </v-card-title>
    </v-card>

    <v-card>
      <v-card-title>
        <span v-if="data_group_detail != null"
          >ข้อมูลใบแจ้งหนี้ : {{ data_group_detail.invoiceNo }} (เวลาออกใบเสร็จ
          {{ data_group_detail.invoiceDate }})</span
        >
        <v-spacer />
        <vue-excel-xlsx
          id="GetExcel"
          v-show="false"
          :data="datalist_for_excel"
          :columns="excel_detail_invoice_list_header"
          :file-name="'รายงานข้อมูลใบแจ้งหนี้'"
          :sheet-name="'รายงานข้อมูลใบแจ้งหนี้'"
        >
        </vue-excel-xlsx>
        <v-btn
          v-if="loadingExcel == 0"
          outlined
          color="success"
          @click="loading_excel_percent()"
          ><v-icon size="16" class="pr-1">fa-file-excel</v-icon> Export Excel
        </v-btn>
        <v-btn v-else outlined color="info"
          ><v-icon size="16" class="pr-1">fa-file-excel</v-icon>
          Export Excel
          <span
            >({{ loadingExcel }}%)
            <v-progress-circular indeterminate color="info" size="25" />
          </span>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <!-- PC -->
        <v-data-table
          :headers="headers"
          :items="datalist"
          hide-default-footer
          :items-per-page="100000"
          item-key="orderID"
          class="elevation-0 packhai-border-table"
          :mobile-breakpoint="0"
        >
          <template v-slot:item.num="{ item }">
            {{ gen_num_data_table(datalist.indexOf(item)) }}
          </template>
          <template v-slot:item.orderID="{ item }">
            <span class="hidden-xs-only" href="#" @click="ShowDetailOrder(item.token)"
              ><a>{{ item.orderID }}</a></span
            >
          </template>
          <template v-slot:item.expressCompanyLogo="{ item }">
            <v-row class="pa-0 ma-0" align="center">
              <v-col class="pa-0 ma-0" align="center">
                <v-img
                  :src="item.expressCompanyLogo == null ? '' : item.expressCompanyLogo"
                  width="50"
                  height="auto"
                ></v-img>
              </v-col>
            </v-row>
          </template>
          <!-- <template v-slot:item.branchToPackhaiDeliveryPrice="{ item }">
            {{ formatMoney(item.branchToPackhaiDeliveryPrice) }}
          </template>
          <template v-slot:item.deliveryPrice="{ item }">
            {{ formatMoney(item.deliveryPrice) }}
          </template>
          <template v-slot:item.branchToPackhaiCODPrice="{ item }">
            {{ formatMoney(item.branchToPackhaiCODPrice) }}
          </template>
          <template v-slot:item.codPrice="{ item }">
            {{ formatMoney(item.codPrice) }}
          </template> -->
        </v-data-table>
        <v-row class="mx-0 my-5">
          <v-col cols="12" align="center">
            <div class="text-center" @click="changePage()">
              <v-pagination
                v-model="page"
                :length="pageLength"
                :total-visible="10"
                color="primary"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
        <v-divider />
        <v-row justify="end" v-if="data_group_detail != null" v-show="true">
          <v-col lg="5">
            <v-row class="py-3 px-4" v-if="ch_IsLogisticInvoice == false">
              <v-col> ค่าธรรมเนียม </v-col>
              <v-col cols="4" lg="3" align="right">
                <span v-if="data_group_detail.transactionFee != null">{{
                  data_group_detail.transactionFee
                }}</span>
                <span v-else-if="datalist.length != 0">{{
                  datalist[datalist.length - 1].transactionFee
                }}</span>
                <span v-else>0.00</span>
              </v-col>
            </v-row>
            <v-divider v-if="ch_IsLogisticInvoice == false"></v-divider>
            <v-row class="py-3 px-4" v-if="ch_IsLogisticInvoice == false">
              <v-col> ค่าเชื่อมต่อ Vrich </v-col>
              <v-col cols="4" lg="3" align="right">
                <span v-if="data_group_detail.vrichFee != null">{{
                  data_group_detail.vrichFee
                }}</span>
                <span v-else-if="datalist.length != 0">{{
                  datalist[datalist.length - 1].vrichFee
                }}</span>
                <span v-else>0.00</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="py-3 px-4" v-if="ch_IsLogisticInvoice == true">
              <v-col> ค่าขนส่ง </v-col>
              <v-col cols="4" lg="3" align="right">
                <span v-if="data_group_detail.deliveryPrice != null">{{
                  data_group_detail.deliveryPrice
                }}</span>
                <span v-else-if="datalist.length != 0">{{
                  datalist[datalist.length - 1].deliveryPrice
                }}</span>
                <span v-else>0.00</span>
              </v-col>
            </v-row>
            <v-divider v-if="ch_IsLogisticInvoice == true"></v-divider>
            <v-row class="py-3 px-4" v-if="ch_IsLogisticInvoice == true">
              <v-col> ค่า COD </v-col>
              <v-col cols="4" lg="3" align="right">
                <span v-if="data_group_detail.codPrice != null">{{
                  data_group_detail.codPrice
                }}</span>
                <span v-else-if="datalist.length != 0">{{
                  datalist[datalist.length - 1].codPrice
                }}</span>
                <span v-else>0.00</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="py-3 px-4">
              <v-col> %VAT ค่าบริการ </v-col>
              <v-col cols="4" lg="3" align="right">
                <span>{{ data_group_detail.serviceVatPercent }}</span>
              </v-col>
            </v-row>
            <v-row class="py-3 px-4">
              <v-col> VAT ค่าบริการ </v-col>
              <v-col cols="4" lg="3" align="right">
                <span>{{ data_group_detail.serviceVatAmount }}</span>
              </v-col>
            </v-row>
            <!-- <v-divider></v-divider> -->
            <v-divider></v-divider>
            <v-row class="py-3 px-4 font-weight-bold">
              <v-col> รวมยอดทั้งหมด </v-col>
              <v-col cols="4" lg="3" align="right">
                <span>{{ data_group_detail.totalPrice }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { header_token } from "@/website/token";
import Loading from "@/website/components/Loading";
import {
  adminService,
  branchOverviewService,
  reportService_dotnet,
} from "@/website/global";
import { AlertError } from "@/website/global_alert";
import { FormatInvoiceBranch, formatMoney, FormatOrder } from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: () => ({
    page_loading: true,
    loading: false,
    headers: [
      { text: "", align: "center", value: "num", sortable: false, width: "5%" },
      {
        text: "ออเดอร์",
        align: "center",
        value: "orderID",
        sortable: false,
        width: "8%",
      },
      {
        text: "ขนส่ง",
        align: "center",
        value: "expressCompanyLogo",
        sortable: false,
        width: "10%",
      },
      // { text: 'ขนส่ง', align: 'center', value: 'expressCompanyName', sortable: false, width: '10%' },
      {
        text: "เลขแทรค",
        align: "center",
        value: "trackingNo",
        sortable: false,
        width: "10%",
      },
      {
        text: "แพ็คสำเร็จ",
        align: "center",
        value: "packedDate",
        sortable: false,
        width: "13%",
      },
      {
        text: "ถึงบริษัทขนส่ง",
        align: "center",
        value: "sendToExpressDateTime",
        sortable: false,
        width: "13%",
      },
      {
        text: "ค่าธรรมเนียม",
        align: "right",
        value: "transactionFee",
        sortable: false,
        width: "10%",
      },
      { text: "ค่าเชื่อต่อ Vrich", value: "vrichFee", sortable: false, align: "right" },
      { text: "ราคารวม", align: "right", value: "totalPrice", sortable: false },
    ],
    datalist: [],
    datalist_for_excel: [],

    data_group_detail: null,

    // excel
    excel_detail_invoice_list_header: [
      { label: "ออเดอร์", field: "orderID", width: 15 },
      { label: "ขนส่ง", field: "expressCompanyName", width: 25 },
      { label: "เลขแทรค", field: "trackingNo", width: 25 },
      { label: "แพ็คสำเร็จ", field: "packedDate", width: 15 },
      { label: "ถึงบริษัทขนส่ง", field: "sendToExpressDateTime", width: 20 },
      { label: "ค่าธรรมเนียม", field: "transactionFee", width: 20 },
      { label: "ค่าเชื่อต่อ Vrich", field: "vrichFee", width: 15 },
      { label: "ราคารวม", field: "totalPrice", width: 15 },
    ],
    loadingExcel: 0,

    // paginations
    isPage: 0,
    page: 1,
    page_tmp: null,
    limits: 50,
    offsets: 0,
    pageLength: null,

    total_order: 0,

    ch_IsLogisticInvoice: false,
  }),
  async created() {
    this.hqInvoiceID = null;
    this.hqInvoiceGroupID = null;
    this.hqLogisticInvoiceID = null;

    if (this.$route.query.inv === "undefined") {
      this.hqInvoiceID = null;
    } else if (this.$route.query.inv == "null") {
      this.hqInvoiceID = null;
    } else if (this.$route.query.inv == null) {
      this.hqInvoiceID = null;
    } else if (this.$route.query.inv == "") {
      this.hqInvoiceID = null;
    } else {
      this.hqInvoiceID = this.$route.query.inv;
    }

    if (this.$route.query.hqInvGroup === "undefined") {
      this.hqInvoiceGroupID = null;
    } else if (this.$route.query.hqInvGroup == "null") {
      this.hqInvoiceGroupID = null;
    } else if (this.$route.query.hqInvGroup == null) {
      this.hqInvoiceGroupID = null;
    } else if (this.$route.query.hqInvGroup == "") {
      this.hqInvoiceGroupID = null;
    } else {
      this.hqInvoiceGroupID = this.$route.query.hqInvGroup;
    }

    if (this.$route.query.hqLogisticInv === "undefined") {
      this.hqLogisticInvoiceID = null;
    } else if (this.$route.query.hqLogisticInv == "null") {
      this.hqLogisticInvoiceID = null;
    } else if (this.$route.query.hqLogisticInv == null) {
      this.hqLogisticInvoiceID = null;
    } else if (this.$route.query.hqLogisticInv == "") {
      this.hqLogisticInvoiceID = null;
    } else {
      this.hqLogisticInvoiceID = this.$route.query.hqLogisticInv;
    }

    if (this.hqLogisticInvoiceID != null) {
      this.hqInvoiceGroupID = this.hqLogisticInvoiceID;
    }
    await this.get_data_hq_invoice_group();
    this.set_hearder();
    await this.get_count_all_order();
    await this.get_invoice(this.limits, this.offsets);
    await this.get_invoice_summary();
    this.page_loading = false;
  },
  methods: {
    set_hearder() {
      if (this.ch_IsLogisticInvoice == false) {
        this.headers = [
          { text: "", align: "center", value: "num", sortable: false, width: "5%" },
          {
            text: "ออเดอร์",
            align: "center",
            value: "orderID",
            sortable: false,
            width: "8%",
          },
          {
            text: "ขนส่ง",
            align: "center",
            value: "expressCompanyLogo",
            sortable: false,
            width: "10%",
          },
          // { text: 'ขนส่ง', align: 'center', value: 'expressCompanyName', sortable: false, width: '10%' },
          {
            text: "เลขแทรค",
            align: "center",
            value: "trackingNo",
            sortable: false,
            width: "10%",
          },
          {
            text: "แพ็คสำเร็จ",
            align: "center",
            value: "packedDate",
            sortable: false,
            width: "13%",
          },
          {
            text: "ถึงบริษัทขนส่ง",
            align: "center",
            value: "sendToExpressDateTime",
            sortable: false,
            width: "13%",
          },
          {
            text: "ค่าธรรมเนียม",
            align: "right",
            value: "transactionFee",
            sortable: false,
            width: "10%",
          },
          {
            text: "ค่าเชื่อต่อ Vrich",
            value: "vrichFee",
            sortable: false,
            align: "right",
          },
          { text: "ราคารวม", align: "right", value: "totalPrice", sortable: false },
        ];

        this.excel_detail_invoice_list_header = [
          { label: "ออเดอร์", field: "orderID", width: 15 },
          { label: "ขนส่ง", field: "expressCompanyName", width: 25 },
          { label: "เลขแทรค", field: "trackingNo", width: 25 },
          { label: "แพ็คสำเร็จ", field: "packedDate", width: 15 },
          { label: "ถึงบริษัทขนส่ง", field: "sendToExpressDateTime", width: 20 },
          { label: "ค่าธรรมเนียม", field: "transactionFee", width: 20 },
          { label: "ค่าเชื่อต่อ Vrich", field: "vrichFee", width: 15 },
          { label: "ราคารวม", field: "totalPrice", width: 15 },
        ];
      } else {
        this.headers = [
          { text: "", align: "center", value: "num", sortable: false, width: "5%" },
          {
            text: "ออเดอร์",
            align: "center",
            value: "orderID",
            sortable: false,
            width: "8%",
          },
          {
            text: "ขนส่ง",
            align: "center",
            value: "expressCompanyLogo",
            sortable: false,
            width: "10%",
          },
          // { text: 'ขนส่ง', align: 'center', value: 'expressCompanyName', sortable: false, width: '10%' },
          {
            text: "เลขแทรค",
            align: "center",
            value: "trackingNo",
            sortable: false,
            width: "10%",
          },
          {
            text: "แพ็คสำเร็จ",
            align: "center",
            value: "packedDate",
            sortable: false,
            width: "13%",
          },
          {
            text: "ถึงบริษัทขนส่ง",
            align: "center",
            value: "sendToExpressDateTime",
            sortable: false,
            width: "13%",
          },
          {
            text: "ค่าส่งคลังเก็บร้านค้า",
            value: "deliveryPrice",
            sortable: false,
            align: "right",
          },
          {
            text: "ค่า COD คลังเก็บร้านค้า",
            value: "codPrice",
            sortable: false,
            align: "right",
          },
          {
            text: "ค่าส่ง HQ เก็บคลัง",
            value: "branchToPackhaiDeliveryPrice",
            sortable: false,
            align: "right",
          },
          {
            text: "ค่า COD HQ เก็บคลัง",
            value: "branchToPackhaiCODPrice",
            sortable: false,
            align: "right",
          },
          { text: "ราคารวม", align: "right", value: "totalPrice", sortable: false },
        ];

        this.excel_detail_invoice_list_header = [
          { label: "ออเดอร์", field: "orderID", width: 15 },
          { label: "ขนส่ง", field: "expressCompanyName", width: 25 },
          { label: "เลขแทรค", field: "trackingNo", width: 25 },
          { label: "แพ็คสำเร็จ", field: "packedDate", width: 15 },
          { label: "ถึงบริษัทขนส่ง", field: "sendToExpressDateTime", width: 20 },
          { label: "ค่าส่งคลังเก็บร้านค้า", field: "deliveryPrice", width: 15 },
          { label: "ค่า COD คลังเก็บร้านค้า", field: "codPrice", width: 15 },
          {
            label: "ค่าส่ง HQ เก็บคลัง",
            field: "branchToPackhaiDeliveryPrice",
            width: 15,
          },
          { label: "ค่า COD HQ เก็บคลัง", field: "branchToPackhaiCODPrice", width: 15 },
          { label: "ราคารวม", field: "totalPrice", width: 15 },
        ];
      }
    },
    async get_count_all_order() {
      let res1 = await axios.post(
        reportService_dotnet + "HQInvoice/get-count-order-detail1",
        {
          id: this.hqInvoiceGroupID,
          groupName: this.GroupName,
        },
        { headers: header_token }
      );
      this.total_order = res1.data;
      this.getpagelength(res1.data);
    },

    async get_invoice(limits, offsets) {
      try {
        let res2 = await axios.post(
          reportService_dotnet + "HQInvoice/group-detail1",
          {
            id: this.hqInvoiceGroupID,
            groupName: this.GroupName,
            limits: limits,
            offsets: offsets,
          },
          { headers: header_token }
        );
        this.datalist = res2.data;
        // this.datalist_for_excel = [...this.datalist]
      } catch (e) {
        this.AlertError();
      }
    },

    async get_invoice_summary() {
      try {
        let res1 = await axios.post(
          reportService_dotnet + "HQInvoice/group-detail-summary",
          {
            id: this.hqInvoiceGroupID,
            groupName: this.GroupName,
            limits: this.total_order,
            offsets: 0,
          },
          { headers: header_token }
        );
        this.data_group_detail = res1.data;
      } catch (e) {
        this.AlertError();
      }
    },

    async get_data_hq_invoice_group() {
      if (this.hqInvoiceGroupID != null) {
        let res1 = await axios.post(
          reportService_dotnet + "HQInvoice/get-hqinvoicegroup-by-id",
          {
            hq_group_id: this.hqInvoiceGroupID,
          },
          { headers: header_token }
        );
        if (res1.data != null) {
          this.ch_IsLogisticInvoice = res1.data.isLogisticInvoice;
        }
      } else {
        this.ch_IsLogisticInvoice = false;
      }
    },

    async ShowDetailOrder(orderToken) {
      window.open("/order-detail?token=" + orderToken);
    },

    getpagelength(total_data) {
      var total = 0;
      if (total_data != 0) {
        total = total_data;
      } else {
        total = 1;
      }

      total = parseInt(total);
      this.pageLength = Math.ceil(total / this.limits);
      this.isPage = 0;
      this.page_tmp = this.page;
    },

    async changePage() {
      this.loading = true;
      if (this.page >= 1 && this.page <= this.pageLength) {
        if (this.page_tmp != this.page) {
          this.offsets = this.page * this.limits - this.limits;
          await this.get_invoice(this.limits, this.offsets);
          this.page_tmp = this.page;
        }
      }
      this.loading = false;
    },

    gen_num_data_table(index) {
      if (index != null) {
        var start = null;
        // if(this.selected == 30){
        start = 1 + this.offsets;
        return start + index;
        // }
        // else {
        //   start = this.total_search_data_order_master - this.offsets
        //   return (start - index )
        // }
      } else {
        return "";
      }
    },

    async loading_excel_percent() {
      this.loadingExcel = 1;
      try {
        this.datalist_for_excel = [];

        var percent = 1;
        var limits = 2000;
        var total = this.total_order;
        var offsets = 0;
        var loadingExcel = 0;

        var Round = parseInt(total / limits);
        if (limits * Round < total) {
          Round = Round + 1;
        }

        for (var i = 1; i <= Round; i++) {
          percent = parseInt((i / Round) * 100);

          var Ndata = await this.get_data_for_excel(limits, offsets);
          for (var jj in Ndata) {
            this.datalist_for_excel.push(Ndata[jj]);
          }
          // this.datalist_for_excel = this.datalist_for_excel.concat(Ndata);
          this.loadingExcel = percent;
          offsets = offsets + limits;
        }

        if (this.datalist_for_excel.length > 0) {
          if (this.ch_IsLogisticInvoice == false) {
            this.datalist_for_excel.push({
              vrichFee: "ค่าธรรมเนียม",
              totalPrice: this.datalist_for_excel[this.datalist_for_excel.length - 1]
                .transactionFee,
            });
            this.datalist_for_excel.push({
              vrichFee: "ค่าเชื่อมต่อ Vrich",
              totalPrice: this.datalist_for_excel[this.datalist_for_excel.length - 1]
                .vrichFee,
            });
            this.datalist_for_excel.push({
              vrichFee: "%VAT ค่าบริการ",
              totalPrice: this.data_group_detail.serviceVatPercent,
            });
            this.datalist_for_excel.push({
              vrichFee: "VAT ค่าบริการ",
              totalPrice: this.data_group_detail.serviceVatAmount,
            });
            this.datalist_for_excel.push({
              vrichFee: "รวมยอดทั้งหมด",
              totalPrice: this.data_group_detail.totalPrice,
            });
          } else {
            this.datalist_for_excel.push({
              branchToPackhaiCODPrice: "ค่าขนส่ง",
              totalPrice: this.data_group_detail.deliveryPrice,
            });
            this.datalist_for_excel.push({
              branchToPackhaiCODPrice: "ค่า COD",
              totalPrice: this.data_group_detail.codPrice,
            });
            this.datalist_for_excel.push({
              branchToPackhaiCODPrice: "%VAT ค่าบริการ",
              totalPrice: this.data_group_detail.serviceVatPercent,
            });
            this.datalist_for_excel.push({
              branchToPackhaiCODPrice: "VAT ค่าบริการ",
              totalPrice: this.data_group_detail.serviceVatAmount,
            });
            this.datalist_for_excel.push({
              branchToPackhaiCODPrice: "รวมยอดทั้งหมด",
              totalPrice: this.data_group_detail.totalPrice,
            });
          }
        }

        await document.getElementById("GetExcel").click();
        this.datalist_for_excel = [];
        this.loadingExcel = 0;
      } catch (e) {
        this.AlertError();
        this.loadingExcel = 0;
        this.datalist_for_excel = [];
      }
    },

    async get_data_for_excel(limits, offsets) {
      let res2 = await axios.post(
        reportService_dotnet + "HQInvoice/group-detail1",
        {
          id: this.hqInvoiceGroupID,
          limits: limits,
          offsets: offsets,
        },
        { headers: header_token }
      );
      return res2.data;
    },
    FormatInvoiceBranch,
    FormatOrder,
    formatMoney,
    AlertError,
  },
};
</script>

<style scoped>
.packhai-border-table {
  /* border:1px solid #e9edf4; */
  border-collapse: collapse;
  border-spacing: 0px;
  border-right: 1px solid #e9edf4;
  border-bottom: 1px solid #e9edf4;
  border-top: 1px solid #e9edf4;
}
.packhai-border-table thead {
  color: white;
}
.packhai-border-table thead tr th {
  color: black !important;
  font-weight: bold;
  font-size: 14px;
}
.packhai-border-table tr {
  /* border-collapse: collapse; */
  /* border: 1px solid #e9edf4; */
}
.packhai-border-table th {
  /* border-collapse: collapse; */
  border-left: 1px solid #e9edf4;
}
.packhai-border-table tr td {
  border-left: 1px solid #e9edf4;
}
</style>
